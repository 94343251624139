import { createRouter, createWebHistory } from "vue-router";
import SignupLogin from "../views/landing/SignupLogin.vue";

const routes = [
  {
    path: "/",
    alias: "/login",
    name: "SignupLogin",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/landing/SignupLogin.vue"),
    redirect: "/login",
    children: [
      {
        path: "/login",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/signuplogin/Login.vue"),
      },
      {
        path: "/signup",
        name: "SignUp",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/signuplogin/Signup.vue"),
      },
      {
        path: "/examnotavailable",
        name: "ExamNotAvailable",
        props: true,
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/signuplogin/ExamNotAvailable.vue"),
      },
    ]
  },
  {
    path: "/home",
    name: "HomePage",
    component: () =>
      import(/* webpackChunkName: "homepage" */ "../views/home/HomePage.vue"),
    redirect: "/home/main",
    children: [
      {
        path: "/home/main",
        name: "HomePage",
        props: true,
        component: () =>
          import(/* webpackChunkName: "homepage" */ "../views/home/HomePage.vue"),
      },
      {
        path: "/home/exam",
        name: "ExamBody",
        props: true,
        component: () =>
          import(/* webpackChunkName: "homepage" */ "../views/exams/ExamBody.vue"),
      },

    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
