<template>
  <router-view />
</template>

<script>
window.$ = require('jquery')
window.JQuery = require('jquery')
import { config } from 'dotenv';
window.$ = require("jquery");
window.JQuery = require("jquery");
window.apiUrl = "https://api.assessr.co.za/api";
window.appUrl = "https://exam.assessr.co.za";
export default {
  created() {
    config();
    const url = window.location.href;
    if (url.includes("local")) {
      window.apiUrl = process.env.VUE_APP_LOCAL;
      window.appUrl = process.env.VUE_APP_FE_LOCAL;
    } else if (url.includes("dev")) {
      window.apiUrl = process.env.VUE_APP_DEV;
      window.appUrl = process.env.VUE_APP_FE_DEV;
    } else if (url.includes("qa")) {
      window.apiUrl = process.env.VUE_APP_QA;
      window.appUrl = process.env.VUE_APP_FE_QA;
    } else if (url.includes("prod")) {
      window.apiUrl = process.env.VUE_APP_PROD;
      window.appUrl = process.env.VUE_APP_FE_PROD;
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: whitesmoke;
}
</style>
